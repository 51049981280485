.scroll-top-to-bottom-animation{
    position: fixed;
    z-index: 20;
    animation: scrollTopAnimation 2s ease  1 forwards 
    
}

.scroll-bottom-to-top-animation{
    position: fixed;
    z-index: 20;
    animation: scrollBottomAnimation 2s ease  1 forwards 

}

@keyframes scrollTopAnimation {
    from {
        top:4vh;
        opacity: 0;
    }
    to {
        top:92vh;
        opacity:1;
    }
}
@keyframes scrollBottomAnimation {
    from {
        top:92vh;
        opacity: 1;
    }
    to {
        top:4vh;
        opacity:0;
    }
}